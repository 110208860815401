import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isExtrenalLink',
  standalone: true,
})
export class ExternalLinkPipe implements PipeTransform {
  transform(url: string): boolean {
    return url.indexOf('https://') === 0 || url.indexOf('http://') === 0 || url.indexOf('www.') === 0;
  }
}
