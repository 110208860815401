import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'content',
  standalone: true,
})
export class ContentPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}

  transform(input: string): SafeHtml {
    let output = '';

    output = input
      .replace(/<p[^>]*>(\s|&nbsp;|<\/?\s?br\s?\/?>)*<\/?p>/g, '')
      .replace(/(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/g, '<span class="embed-container">$&</span>')
      .replace(/(?:<table.*?>(.*?)<\/table>)/gs, '<div class="table-container">$&</div>');

    return this.sanitized.bypassSecurityTrustHtml(output);
  }
}
